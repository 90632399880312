import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzImageModule} from 'ng-zorro-antd/image';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzMessageModule} from 'ng-zorro-antd/message';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzPopconfirmModule} from 'ng-zorro-antd/popconfirm';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {WorkspaceCardComponent} from './workspace-card.component';

@NgModule({
  declarations: [WorkspaceCardComponent],
  exports: [WorkspaceCardComponent],
  imports: [
    NzCardModule,
    NzAvatarModule,
    NzIconModule,
    NzButtonModule,
    NzImageModule,
    NzSkeletonModule,
    NzEmptyModule,
    NzFormModule,
    NzMessageModule,
    NzPopconfirmModule,
    NzModalModule,
    RouterModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NzInputModule,
    NzSpinModule,
  ],
})
export class WorkspaceCardModule {}
