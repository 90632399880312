import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/workspaces'},
  // {path: '', pathMatch: 'full', component: AppComponent},
  {
    path: 'workspaces',
    loadChildren: () =>
      import('./pages/workspaces/workspaces.module').then(
        (m) => m.WorkspacesModule,
      ),
  },
  {
    path: 'workspace/:workspaceId',
    loadChildren: () =>
      import('./pages/workspace/workspace.module').then(
        (m) => m.WorkspaceModule,
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
