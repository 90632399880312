import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable, of, switchMap} from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import {GlobalSettings} from '../models/global-settings';
import {ServerEvent} from '../models/server-event';

/** API Services. */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getEvents(
    sessionId: string,
    prompt: string,
    transparency: number,
    safetyFilterLevel: string,
    language: string,
    personGenerationSetting: string,
    negativePrompt: string,
    aspectRatio: Record<string, string>,
    directGenreation: boolean,
    useSeed: boolean,
    settings: GlobalSettings | null,
  ): Observable<any> {
    const queryParams = new HttpParams({
      fromObject: {
        session_id: sessionId,
        prompt,
        direct_generation: directGenreation,
        transparency_level: transparency,
        safety_filter_level: safetyFilterLevel,
        language,
        person_generation: personGenerationSetting,
        negative_prompt: negativePrompt,
        seed: 1,
        aspect_ratio: encodeURIComponent(JSON.stringify(aspectRatio)),
        gemini_model_name: settings!.geminiModelVersion,
        imagen_model_name: settings!.imageGenModelVersion,
        number_of_images: settings!.numberOfImages,
        use_seed: useSeed,
      },
    });
    let url = `/events?${queryParams}`;

    return new Observable<ServerEvent>((observer) => {
      const eventSource = new EventSource(url, {withCredentials: true});

      eventSource.onmessage = (event) => {
        const parsedPayload = JSON.parse(event.data);

        if (parsedPayload.status === 'finished') {
          const eventObj = {
            status: 'finished',
            image: undefined,
            message: parsedPayload.message,
          };
          if (eventObj.message !== '') {
            observer.error(eventObj);
          } else {
            observer.next(eventObj);
          }
          observer.complete();
          eventSource.close();
        } else if (parsedPayload.status === 'started') {
          const eventObj = {
            status: 'started',
            image: undefined,
          };
          observer.next(eventObj);
        } else {
          const eventObj = {
            status: 'processing',
            image: {
              id: uuidv4(),
              composedImageUrl: parsedPayload.composed_image_url,
              backgroundImageUrl: parsedPayload.background_image_url,
              promptForImageGeneration:
                parsedPayload.prompt_for_image_generation,
              expirationDate: new Date(),
            },
          };
          observer.next(eventObj);
        }
      };

      eventSource.onerror = (error) => {
        observer.error(error);
        eventSource.close();
      };

      return () => eventSource.close();
    });
  }

  downloadImage(url: string): Observable<any> {
    return from(fetch(url)).pipe(
      switchMap((resp) => from(resp.blob())),
      switchMap((blob) => {
        const url = window.URL.createObjectURL(blob);
        const fileName = url.split('/').pop() ?? 'not_found.png';
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return of();
      }),
    );
  }

  deleteUploadedFile(uuid: string, fileName: string): Observable<any> {
    const params: any = {
      uuid,
      'file_name': fileName,
    };

    return this.http.delete('/upload', {
      params,
      withCredentials: true,
    });
  }

  validateBackground(
    campaignDetails: string,
    companySpecificRules: string,
    backgroundUrl: string,
    settings: GlobalSettings | null,
  ): Observable<any> {
    let params: any = {
      'campaign_details': campaignDetails,
      'company_specific_rules': companySpecificRules,
      'url': backgroundUrl,
    };
    if (settings !== null) {
      params = {
        ...params,
        'gemini_model_name': settings.geminiModelVersion,
      };
    }
    return this.http.get('/validate-background', {
      params,
      withCredentials: true,
    });
  }

  describeImageBackground(
    backgroundUrl: string,
    settings: GlobalSettings | null,
  ): Observable<any> {
    let params: any = {
      'url': backgroundUrl,
    };
    if (settings !== null) {
      params = {
        ...params,
        'gemini_model_name': settings.geminiModelVersion,
      };
    }
    return this.http.get('/describe-image-background', {
      params,
      withCredentials: true,
    });
  }

  deleteImageBackground(backgroundUrl: string): Observable<any> {
    const params: any = {
      'url': backgroundUrl,
    };

    return this.http.delete('/upload-background', {
      params,
      withCredentials: true,
    });
  }
}
