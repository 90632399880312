import {Injectable} from '@angular/core';
import {Dexie, IndexableType} from 'dexie';
import {Observable, from, map, of, switchMap} from 'rxjs';
import {GeneratedImage} from '../models/generated-image';
import {GlobalSettings} from '../models/global-settings';
import {PreviousPrompt} from '../models/previous-prompt';
import {Workspace} from '../models/workspace';

const DATABASE_NAME = 'genai_big_database';
const WORKSPACES_STORE_NAME = 'workspaces';
const GLOBAL_SETTINGS_STORE_NAME = 'globalSettings';

/**
 * IDB service.
 */
@Injectable({providedIn: 'root'})
export class IDBService {
  private db: Dexie;

  constructor() {
    this.db = new Dexie(DATABASE_NAME);
    this.db.version(1).stores({
      [WORKSPACES_STORE_NAME]: 'id', // Define the 'workspaces' store with 'id' as the primary key
      [GLOBAL_SETTINGS_STORE_NAME]: 'id', // Define the 'globalSettings' store with 'id' as the primary key
    });
  }

  getAllWorkspaces(): Observable<Workspace[]> {
    // TODO the sorting logic should not be added here for many elements
    return from(this.db.table(WORKSPACES_STORE_NAME).toArray()).pipe(
      map((workspaces) =>
        [...workspaces].sort((a, b) => {
          console.log('sorting');
          if (!a.creationDate || !b.creationDate) {
            return 0;
          }
          return (
            new Date(b.creationDate).getTime() -
            new Date(a.creationDate).getTime()
          ); // Descending order
        }),
      ),
    );
  }

  // saveWorkspace(workspace: Workspace): Observable<IndexableType> {
  //   return from(this.db.table(WORKSPACES_STORE_NAME).put(workspace));
  // }

  saveWorkspace(workspace: Workspace): Observable<string> {
    return from(this.db.table(WORKSPACES_STORE_NAME).put(workspace)).pipe(
      map((result: IndexableType) => result as string),
    );
  }

  getWorkspace(id: string): Observable<Workspace | undefined> {
    return from(this.db.table(WORKSPACES_STORE_NAME).get(id));
  }

  getWorkspaceName(id: string): Observable<string | undefined> {
    return from(
      this.db
        .table(WORKSPACES_STORE_NAME)
        .get(id)
        .then((workspace: Workspace) => {
          const workspaceName = workspace.name;
          if (workspaceName.length > 20) {
            return workspaceName.substring(0, 20) + '...';
          }
          return workspaceName;
        }),
    );
  }

  getAllPreviousPromptsForWorkspace(
    workspaceId: string,
  ): Observable<PreviousPrompt[]> {
    return from(
      this.db
        .table(WORKSPACES_STORE_NAME)
        .get(workspaceId)
        .then((workspace: Workspace) => workspace.previousPrompts),
    );
  }

  getAllGeneratedImagesForWorkspace(
    workspaceId: string,
  ): Observable<GeneratedImage[]> {
    return from(
      this.db
        .table(WORKSPACES_STORE_NAME)
        .get(workspaceId)
        .then((workspace: Workspace) => workspace.generatedImages),
    );
  }

  getGeneratedImage(
    workspaceId: string,
    imageId: string,
  ): Observable<GeneratedImage | undefined> {
    return from(
      this.db
        .table(WORKSPACES_STORE_NAME)
        .get(workspaceId)
        .then((workspace: Workspace) => {
          const image = workspace.generatedImages.find(
            (image: GeneratedImage) => image.id === imageId,
          );
          return image;
        }),
    );
  }
  updateGeneratedImage(
    workspaceId: string,
    imageToUpdate: GeneratedImage,
  ): Observable<number> {
    return from(
      this.db
        .table(WORKSPACES_STORE_NAME)
        .get(workspaceId)
        .then((workspace: Workspace) => {
          const index = workspace.generatedImages.findIndex(
            (existingImage: GeneratedImage) =>
              imageToUpdate.id === existingImage.id,
          );
          if (index > -1) {
            workspace.generatedImages[index] = imageToUpdate;
            return this.db
              .table(WORKSPACES_STORE_NAME)
              .update(workspaceId, workspace);
          }
          return 0;
        }),
    );
  }

  deleteWorkspace(id: string): Observable<void> {
    return from(this.db.table(WORKSPACES_STORE_NAME).delete(id));
  }

  updateWorkspace(id: string, updates: Partial<Workspace>): Observable<number> {
    return from(this.db.table(WORKSPACES_STORE_NAME).update(id, updates));
  }

  saveGlobalSettings(settings: GlobalSettings): Observable<string> {
    // Ensure there's only one global settings entry (using 'put' with the same ID)
    return from(
      this.db.table(GLOBAL_SETTINGS_STORE_NAME).put({id: '1', ...settings}),
    ).pipe(map((result: IndexableType) => result as string));
  }

  getGlobalSettings(): Observable<GlobalSettings | undefined> {
    return from(this.db.table(GLOBAL_SETTINGS_STORE_NAME).get('1'));
  }

  appendGeneratedImage(workspaceId: string, image: GeneratedImage) {
    return from(this.db.table(WORKSPACES_STORE_NAME).get(workspaceId)).pipe(
      switchMap((workspace: Workspace) => {
        workspace.generatedImages.unshift(image);
        return of(workspace);
      }),
      switchMap((workspace: Workspace) => {
        if (workspace.frontPageImageUrl === 'not_found.png') {
          workspace.frontPageImageUrl = image.composedImageUrl;
          return this.updateWorkspace(workspaceId, workspace);
        }
        return this.updateWorkspace(workspaceId, workspace);
      }),
    );
  }

  appendPreviousPrompt(workspaceId: string, prompt: PreviousPrompt) {
    return from(this.db.table(WORKSPACES_STORE_NAME).get(workspaceId)).pipe(
      switchMap((workspace: Workspace) => {
        workspace.previousPrompts.unshift(prompt);
        return of(workspace);
      }),
      switchMap((workspace: Workspace) => {
        return this.updateWorkspace(workspaceId, workspace);
      }),
    );
  }

  updateWorkspaceAndAppendPreviousPrompt(
    workspaceId: string,
    updates: Partial<Workspace>,
    prompt: PreviousPrompt,
  ): Observable<number> {
    return from(this.db.table(WORKSPACES_STORE_NAME).get(workspaceId)).pipe(
      switchMap((workspace: Workspace) => {
        workspace.previousPrompts.unshift(prompt);
        return of({...workspace, ...updates} as Workspace);
      }),
      switchMap((workspaceAfterModifications: Workspace) => {
        return this.updateWorkspace(workspaceId, workspaceAfterModifications);
      }),
    );
  }

  appendForegroundImage(workspaceId: string, url: string) {
    return from(this.db.table(WORKSPACES_STORE_NAME).get(workspaceId)).pipe(
      switchMap((workspace: Workspace) => {
        workspace.foregroundImageStoragePaths.push(url);
        return of(workspace);
      }),
      switchMap((workspace: Workspace) => {
        return this.updateWorkspace(workspaceId, workspace);
      }),
    );
  }

  // TODO REMOVE AS THIS IS DUMMY LOGIC.
  appendGeneratedImagesToWorkspace(
    id: string,
    generatedDummyImages: GeneratedImage[],
  ): Observable<number> {
    return from(
      this.db
        .table(WORKSPACES_STORE_NAME)
        .get(id)
        .then((workspace: Workspace) => {
          workspace.generatedImages.push(...generatedDummyImages);
          return this.db.table(WORKSPACES_STORE_NAME).update(id, workspace);
        }),
    );
  }

  deleteGeneratedImage(
    workspaceId: string,
    imageId: string,
  ): Observable<number> {
    return from(
      this.db
        .table(WORKSPACES_STORE_NAME)
        .get(workspaceId)
        .then((workspace: Workspace) => {
          const index = workspace.generatedImages.findIndex(
            (image: GeneratedImage) => image.id === imageId,
          );
          if (index > -1) {
            workspace.generatedImages.splice(index, 1);
            return this.db
              .table(WORKSPACES_STORE_NAME)
              .update(workspaceId, workspace);
          }
          return 0;
        }),
    );
  }
}
