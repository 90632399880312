/**
 * All possible Image Generation Model Versions.
 */
export enum ImageGenModelVersionEnum {
  V_1 = 'imagegeneration@001',
  V_2 = 'imagegeneration@002',
  V_3 = 'imagegeneration@003',
  V_4 = 'imagegeneration@004',
  V_5 = 'imagegeneration@005',
  V_6 = 'imagegeneration@006',
  IMAGEN_3_0_V_1 = 'imagen-3.0-generate-001',
  IMAGEN_3_0_V_1_FAST = 'imagen-3.0-fast-generate-001',
}
